import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { devices } from "../../theme/devices";
import Paragraph from "../atoms/Paragraph";
import Strong from "../atoms/Strong";
import { TabsWithText } from "../atoms/TabsWithText";
import { MainRankItem } from "./MainRankItem";

export const RankingItem = (props) => {
    const { totalQuantity, user, position, orderQuantity } = props;
    const { id } = user;

    return (
        <Wrapper>
            <Number>{position}</Number>
            <Information>
                <SParagraph>
                    ID użytkownika: <strong>{id}</strong>
                </SParagraph>
                <SParagraph>
                    Utworzone: <Strong>{totalQuantity}</Strong>
                </SParagraph>
                <TabsWithText>
                    Zrealizowane: <Strong>{orderQuantity}</Strong>
                </TabsWithText>
            </Information>
        </Wrapper>
    );
};

MainRankItem.propTypes = {
    userID: PropTypes.number,
    crated: PropTypes.number,
    totalQuantity: PropTypes.number,
    orderQuantity: PropTypes.number,
    position: PropTypes.number,
    main: PropTypes.bool,
};

MainRankItem.defaultProps = {
    userID: 0,
    crated: 0,
    totalQuantity: 0,
    orderQuantity: 0,
    position: 0,
    prize: 0,
};

const Wrapper = styled.div`
    padding: 0 20px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    background: ${({ theme }) => theme.backgroundColor};
    border-radius: 14px;

    &:last-of-type {
        margin-bottom: 20px;
    }

    @media (${devices.md}) {
        padding: 10px 20px;
    }
`;

const Information = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    flex: 1;
    text-align: center;

    @media (${devices.md}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

const Number = styled.span`
    width: 40px;
    aspect-ratio: 1;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.primaryTextColor};
    border: 2px solid ${({ theme }) => theme.primaryTextColor};
    border-radius: 100vmax;
    margin-right: 20px;
`;

const SParagraph = styled(Paragraph)`
    margin: 0;
`;
