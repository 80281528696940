import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "../components/molecules/ScrollToTop";
import MainTemplates from "../templates/MainTemplates";
import { CategoryProductPage } from "./CategoryProductPage";
import DetailProductPage from "./DetailProductPage";
import HomePage from "./HomePage";
import Page from "./Page";
import { routes } from "../routes";
import RankingPage from "./RankingPage";
import NewsPage from "./NewsPage";
import DetailNewsPage from "./DetailNewsPage";
import { ProductsPage } from "./ProductsPage";
import { StatusPage } from "./StatusPage";
import PageNotFound from "./PageNotFound";

const Root = () => (
    <BrowserRouter>
        <MainTemplates>
            <ScrollToTop />
            <Switch>
                <Route exact path={routes.home} component={HomePage} />
                <Route exact path={routes.ranking} component={RankingPage} />
                <Route exact path="/info/:id" component={Page} />
                <Route exact path={routes.news} component={NewsPage} />
                <Route exact path={routes.news + "/:id"} component={DetailNewsPage} />
                <Route exact path={routes.products} component={ProductsPage} />
                <Route exact path={routes.products + "/:id"} component={DetailProductPage} />
                <Route exact path={routes.category} component={CategoryProductPage} />
                <Route exact path={routes.state} component={StatusPage} />
                <Route path="*" component={PageNotFound} />
            </Switch>
        </MainTemplates>
    </BrowserRouter>
);
export default Root;
