import React from "react";
import styled from "styled-components";
import { devices } from "../../theme/devices";
import { Container } from "../atoms/Container";
import Paragraph from "../atoms/Paragraph";

export const Copyright = () => {
    const data = new Date();
    const actualYear = data.getFullYear();

    return (
        <Container>
            <SParagraph>{actualYear} © Wszelkie prawa zastrzeżone</SParagraph>
        </Container>
    );
};

const SParagraph = styled(Paragraph)`
    text-align: center;
    font-size: 14px;
    opacity: 0.6;

    @media (${devices.md}) {
        text-align: left;
        margin-bottom: 15px;
    }
`;
