import React from "react";
import styled from "styled-components";
import Heading from "../components/atoms/Heading";
import Button from "../components/atoms/Button";
import PageNotFoundTemplates from "../templates/PageNotFoundTemplates";
import image from "../assets/undraw_not_found_60pq.svg";
import Paragraph from "../components/atoms/Paragraph";
import { routes } from "../routes";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
    background: ${({ theme }) => theme.primaryTextColor};
    display: flex;
    flex-wrap: wrap;
    padding: 180px 0 80px;
    align-items: center;
`;
const InnerWrapper = styled.div`
    width: 45%;
    @media (max-width: ${({ theme }) => theme.phone}) {
        text-align: center;
        width: 100%;
    }
`;
const Image = styled.img`
    width: 50%;
    @media (max-width: ${({ theme }) => theme.phone}) {
        display: none;
    }
`;

const PageNotFound = () => (
    <PageNotFoundTemplates>
        <Wrapper>
            <InnerWrapper>
                <Heading white>Nie znaleziono strony.</Heading>
                <Paragraph white margin={"20px 0"} size="18px">
                    Strona o podanym adresie nie istnieje, kliknij przycisk jeśli chcesz przejść do strony głównej.
                </Paragraph>
                <Button as={NavLink} to={routes.home}>
                    Strona główna
                </Button>
            </InnerWrapper>
            <Image src={image} />
        </Wrapper>
    </PageNotFoundTemplates>
);

export default PageNotFound;
