import React, { useEffect, useContext } from "react";
import { PageContext } from "../../context/PageContext";
import styled from "styled-components";
import { devices } from "../../theme/devices";
import { Container } from "../atoms/Container";
import Heading from "../atoms/Heading";
import { LinkWithArrow } from "../atoms/LinkWithArrow";
import Paragraph from "../atoms/Paragraph";
import { MainRankItem } from "../molecules/MainRankItem";
import axios from "axios";
import { api } from "../../api";

const RankingBar = () => {
    const { ranking, setRanking, isLoadRanking, setIsLoadRanking } = useContext(PageContext);

    useEffect(() => {
        if (isLoadRanking) return;

        const date = new Date();
        const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
        axios
            .get(`${api.apiUrl}/user/ranking/${date.getFullYear()}-${month}`, {
                headers: {
                    appToken: api.appToken,
                },
            })
            .then(({ data }) => {
                setRanking([...data.data]);
                setIsLoadRanking(true);
            })
            .catch((err) => console.log(err));
    }, []);

    if (!isLoadRanking) {
        return <></>;
    }

    return (
        <Wrapper>
            <SContainer>
                <RankingWrapper>
                    <HeadingPrefix>top 10</HeadingPrefix>
                    <SHeading>Miesięczny ranking</SHeading>
                    <SParagraph>
                        Najlepszych sprzedawców wynagradzamy premiami w każdym miesiącu, możesz zgarnąć dodatkowe 400zł.
                    </SParagraph>
                    <SParagraph>
                        Sprawdź ile zamówień mają najlepsi i podejmij walkę.
                    </SParagraph>
                    <LinkWithArrow text="zobacz więcej" primary={true} href="/ranking" />
                </RankingWrapper>
                <RankingWrapper>
                    {ranking.map((props, index) => (
                        <MainRankItem key={index} {...props} />
                    ))}
                </RankingWrapper>
            </SContainer>
        </Wrapper>
    );
};

export default RankingBar;

const Wrapper = styled.div`
    padding: 50px 0;
    background-color: ${({ theme }) => theme.tertiaryColor};
`;

const HeadingPrefix = styled(Paragraph)`
    text-transform: uppercase;
    color: ${({ theme }) => theme.tertiaryAccentColor};
    background-color: ${({ theme }) => theme.tertiaryAccentAltColor};
    padding: 8px 12px;
    border-radius: 12px;
    margin-bottom: 20px;
    display: inline-block;
    font-weight: 700;
    font-size: 18px;
`;

const SHeading = styled(Heading)`
    margin-bottom: 20px;
    color: white;
`;

const SParagraph = styled(Paragraph)`
    margin-bottom: 20px;
    max-width: 420px;
    color: white;
`;

const SContainer = styled(Container)`
    @media (${devices.md}) {
        gap: 10px;
        display: flex;
        align-items: center;
    }
`;

const RankingWrapper = styled.div`
    width: 100%;

    &:first-of-type {
        margin-bottom: 40px;

        @media (${devices.md}) {
            margin-bottom: 0;
        }
    }
`;
