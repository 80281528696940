import styled from "styled-components";

const PaginationItem = styled.span`
    background: ${({ theme, active }) => (active ? theme.primaryColor : theme.backgroundColor)};
    color: ${({ active }) => (active ? "white" : "black")};
    font-weight: ${({ active }) => (active ? 700 : 400)};
    margin: 3px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.primaryColor};
        font-weight: 700;
        color: white;
    }
`;

export default PaginationItem;
