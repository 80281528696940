import React from "react";
import styled from "styled-components";
import { data } from "../../static/data";
import { devices } from "../../theme/devices";
import Button from "../atoms/Button";
import { Container } from "../atoms/Container";
import Heading from "../atoms/Heading";
import { routes } from "../../routes";

const MainHeader = () => {
    const { heading, description } = data.mainHeader;

    return (
        <Wrapper>
            <StyledContainer fluid>
                <Description>
                    <HeadingPrefix>Zacznij z nami współprace</HeadingPrefix>
                    <StyledHeading as={"h1"} dangerouslySetInnerHTML={{ __html: heading }} />
                    <Content dangerouslySetInnerHTML={{ __html: description }} />
                    <ButtonsWrapper>
                        <StyledButton primary href={routes.salesPanel}>
                            Dołącz do nas
                        </StyledButton>
                        <StyledButton transparent href={routes.salesPanel}>
                            Kontakt
                        </StyledButton>
                    </ButtonsWrapper>
                </Description>
            </StyledContainer>
        </Wrapper>
    );
};

export default MainHeader;

const Wrapper = styled.header`
    margin: 10px;
    padding: 80px 0;
    text-align: center;
    background: ${({ theme }) => theme.backgroundColor};

    @media (${devices.md}) {
        margin: 100px 20px 20px;
        text-align: left;
    }
`;

const StyledContainer = styled(Container)`
    @media (${devices.md}) {
        display: flex;
        justify-content: center;
    }
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    text-align: center;

    @media (${devices.md}) {
        max-width: 80%;
    }
`;

const HeadingPrefix = styled("p")`
    font-family: ${({ theme }) => theme.secondaryFontFamily};
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.secondaryAltColor};
`;

const StyledHeading = styled(Heading)`
    font-size: 26px;
    font-weight: 900;

    @media (${devices.md}) {
        font-size: 70px;
    }

    span {
        color: ${({ theme }) => theme.primaryColor};
    }
`;

const Content = styled("div")`
    font-family: ${({ theme }) => theme.secondaryFontFamily};
    font-size: 16px;
    margin: 0 0 30px;
    line-height: 30px;
    font-weight: 200;

    @media (${devices.md}) {
        margin-bottom: 50px;
        font-size: 18px;
        max-width: 1000px;
    }
`;

const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (${devices.md}) {
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }
`;

const StyledButton = styled(Button)`
    margin-bottom: 25px;
    font-size: 20px;
`;
