import React, { useContext } from "react";
import PageTemplates from "../templates/PageTemplates";
import Loading from "../components/molecules/Loading";
import styled from "styled-components";
import Post from "../components/molecules/Post";
import { PageContext } from "../context/PageContext";
import { devices } from "../theme/devices";

const NewsPage = () => {
    const { news, isLoadNews } = useContext(PageContext);

    return isLoadNews ? (
        <PageTemplates title="Aktualności">
            <Wrapper>
                {news.map(({ title, friendlyUrl, datePublished }) => (
                    <Post key={friendlyUrl} name={title} date={datePublished} id={friendlyUrl} />
                ))}
            </Wrapper>
        </PageTemplates>
    ) : (
        <Loading />
    );
};

export default NewsPage;

const Wrapper = styled.div`
    @media (${devices.md}) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
`;
