import React from "react";
import styled from "styled-components";
import { Container } from "../components/atoms/Container";
import Header from "../components/organisms/Header";
import Banner from "../components/organisms/Banner";

const PageTemplates = ({ title, children }) => (
    <>
        <Header title={title} />
        <Container>
            <Content>{children}</Content>
        </Container>
        <Banner />
    </>
);

export default PageTemplates;

const Content = styled.div`
    padding: 25px 0;
`;
