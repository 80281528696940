export const stepsCards = [
    {
        id: "1",
        title: "Wybierasz i wystawiasz produkty",
        content:
            "<p>Korzystając z Facebook Marketplace (lub innej platformy – oprócz Allegro), wystawiasz dowolne dostępne u nas produkty. Za każdą sprzedaną rzecz otrzymujesz prowizję (nawet kilkadziesiąt złotych) </p>" +
            "<p>Do wyboru masz kilkaset różnych produktów, a baza wciąż się powiększa. Dzięki temu łatwiej dobrać sprzedawane przedmioty do grupy docelowej. Na liście znajdziesz m.in. meble, sprzęt AGD i RTV, narzędzia, zabawki czy sprzęt sportowy.</p>" +
            "<p>Pamiętaj! Dostarczamy Ci zdjęcia i opisy produktów, wszystko jest do Twojej dyspozycji. Nie musisz niczego samodzielnie szukać, pisać czy wymyślać, co oszczędza Ci sporo czasu. Wystawienie jednego ogłoszenia to tylko 2 minuty!</p>",
    },
    {
        id: "2",
        title: "Rozmawiasz z klientami",
        content:
            "<p>Gdy wystawisz produkty na Facebook Marketplace, klienci zaczną do Ciebie pisać. Wystarczy rozmawiać z nimi na temat sprzedawanych produktów i zbierać dane do wysyłki. Im szybciej odpiszesz, tym większa szansa na sprzedaż. Jeśli masz jakieś pytania, np. dotyczące specyfikacji danego przedmiotu, jesteśmy dla Ciebie dostępni.</p>" +
            "<p>Czy wiesz, że… czasami może pisać do Ciebie nawet 80 klientów na dzień? Zakładając, że połowa z nich zdecyduje się na zakup produktu z prowizją 10 złotych – to aż 400 zł dziennie dla Ciebie!</p>",
    },
    {
        id: "3",
        title: "Wprowadzasz zamówienie do naszego systemu",
        content:
            "<p>Kiedy klient kupi produkt lub produkty i poda Ci dane do wysyłki, wystarczy wprowadzić niezbędne informacje do naszego systemu. Dzięki temu sfinalizujemy zamówienie i wyślemy produkt. Tutaj kończy się Twoja rola – wszystkie formalności zostaw nam!</p>" +
            "<p>Kupiony produkt jest wysyłany do klienta kurierem za pobraniem. Do każdego zamówienia dokładamy paragon lub fakturę.</p>",
    },
    {
        id: "4",
        title: "Otrzymujesz pieniądze i wypłacasz je w dowolnym momencie",
        content:
            "<p>Gdy klient zapłaci za swój zakup, otrzymasz prowizję za swoje zamówienie. Wybieraj te produkty, dzięki którym zarobisz jak najwięcej. Chcesz wystawić jakiś produkt drożej? Nie ma sprawy – dla Ciebie to dodatkowy zysk.</p>" +
            "<p>Przykładowo: sugerowana cena to 30 zł, a prowizja 5 zł. Jeśli sprzedasz produkt za 40 zł, otrzymasz 5 zł + dodatkowo 10 zł (różnica, którą udało Ci się dorzucić).</p>" +
            "<p>Wypracowane w ten sposób pieniądze możesz wypłacić ze swojego konta w dowolnym momencie (minimalna kwota to 20 zł). To nawet kilka tysięcy zł zarobku miesięcznie!</p>",
    },
];
