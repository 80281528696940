import React, { useContext } from "react";
import PageTemplates from "../templates/PageTemplates";
import Loading from "../components/molecules/Loading";
import Heading from "../components/atoms/Heading";
import Date from "../components/atoms/Date";
import { PageContext } from "../context/PageContext";
import PageNotFound from "./PageNotFound";

const DetailNewsPage = (props) => {
    const { news, isLoadNews } = useContext(PageContext);

    if (!isLoadNews || !news) {
        return (
            <PageTemplates>
                <Loading />
            </PageTemplates>
        );
    }

    const newsToRender = news.find((content) => content?.friendlyUrl === props.match.params.id) ?? "";
    const { title, content, datePublished } = newsToRender;

    if (!newsToRender) {
        return <PageNotFound />;
    }

    return (
        <PageTemplates title="Aktualności">
            <Date>{datePublished}</Date>
            <Heading>{title}</Heading>
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </PageTemplates>
    );
};
export default DetailNewsPage;
