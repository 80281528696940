import React from "react";
import styled from "styled-components";
import Paragraph from "../atoms/Paragraph";

const BenefitCard = ({ header, description }) => {
    return (
        <div>
            <Heading>{header}</Heading>
            <Paragraph>{description}</Paragraph>
        </div>
    );
};

export default BenefitCard;

const Heading = styled("h3")`
    font-family: ${({ theme }) => theme.secondaryFontFamily};
    font-size: 18px;
    margin-bottom: 16px;
    max-width: 80%;
`;
