import styled, { css } from "styled-components";
import expandMore from "../../assets/icons/expand-more.svg";
import { devices } from "../../theme/devices";

const NavigationLink = styled.a`
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    color: ${({ theme }) => theme.primaryTextColor};
    font-weight: 900;
    transition: color 0.2s;
    display: block;

    @media (${devices.md}) {
        font-size: 14px;
    }

    &:hover {
        color: black;
    }

    ${({ dropdown }) =>
        dropdown &&
        css`
            position: relative;
            padding-right: 30px;
            width: 100%;

            &:before {
                content: "";
                position: absolute;
                right: 0;
                width: 24px;
                height: 24px;
                background: url(${expandMore});
                background-size: 100%;
                transition-duration: 1s;
                transform: ${({ activeItem }) => (activeItem ? "rotate(180deg)" : "rotate(0)")};
            }
        `}

    ${({ footer }) =>
        footer &&
        css`
            font-family: ${({ theme }) => theme.secondaryFontFamily};
            font-size: 14px;
            margin-bottom: 10px;
            width: 100%;
            font-weight: 400;
            padding: 0;

            @media (${devices.md}) {
                //font-size: 16px;
            }
        `};
`;

export default NavigationLink;
