import React from "react";
import styled from "styled-components";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const ImageItem = styled.div`
    display: flex;
    overflow: hidden;
    width: 100%;
`;

const MainImage = styled(LazyLoadImage)`
    max-width: 100%;
    max-height: 800px;
    object-fit: cover;
`;

const ProductImages = ({ images }) => {
    const mainImg = images[0];

    return (
        <SimpleReactLightbox>
            <SRLWrapper>
                <Wrapper>
                    <ImageItem>
                        <a href={mainImg}>
                            <MainImage src={mainImg} effect="blur" />
                        </a>
                    </ImageItem>
                </Wrapper>
            </SRLWrapper>
        </SimpleReactLightbox>
    );
};
export default ProductImages;
