import React from "react";
import styled from "styled-components";
import { data } from "../../static/data";
import { devices } from "../../theme/devices";
import { Container } from "../atoms/Container";
import Heading from "../atoms/Heading";
import Paragraph from "../atoms/Paragraph";
import icCheck from "../../assets/icons/ic_check_white.svg";
import imgMain from "../../assets/images/undraw_exams_re_4ios.svg";
import PromoCard from "./PromoCard";

const AboutUs = () => {
    const { promoCards } = data;

    return (
        <>
            <Wrapper>
                <Content>
                    <StyledHeading>Współpraca krok po kroku – praca przez internet prostsza niż kiedykolwiek</StyledHeading>
                    <Paragraph>
                        Szukasz sposobu na zarobek przez internet? Masz dar przekonywania? Lubisz kontakt z ludźmi? Doskonale radzisz sobie w handlu?
                        Teny to opcja dla Ciebie!
                    </Paragraph>
                    <List>
                        <Item>komputer, tablet, laptop lub telefon komórkowy</Item>
                        <Item>internet i konto na Facebooku lub innym portalu</Item>
                        <Item>czas i zaangażowanie (im większe, tym więcej zarobisz).</Item>
                    </List>
                </Content>
                <div>
                    <Image src={imgMain} alt="about us" />
                </div>
            </Wrapper>
            <CardWrapper>
                {promoCards.map((card) => (
                    <PromoCard key={card.id} {...card} />
                ))}
            </CardWrapper>
        </>
    );
};

export default AboutUs;

const Wrapper = styled(Container)`
    @media (${devices.md}) {
        display: flex;
        align-items: center;
    }
`;

const CardWrapper = styled(Container)`
    padding: 50px 15px 100px;

    @media (${devices.md}) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
    }
`;

const Content = styled.div`
    @media (${devices.md}) {
        margin: 100px 0 60px;
        width: 60%;
    }
`;

const Image = styled("img")`
    width: 100%;
`;

const StyledHeading = styled(Heading)`
    margin-bottom: 20px;
`;

const List = styled("ul")`
    margin-top: 30px;
    list-style: none;
    padding: 0;
`;

const Item = styled("li")`
    padding-left: 32px;
    position: relative;
    margin-bottom: 20px;

    &:before {
        content: "";
        width: 24px;
        height: 24px;
        background: url(${icCheck});
        background-color: ${({ theme }) => theme.primaryColor};
        background-size: 100%;
        border-radius: 100vmax;
        position: absolute;
        left: 0;
        top: 0;
    }
`;
