import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { routes } from "../../routes";
import styled from "styled-components";
import { devices } from "../../theme/devices";
import Heading from "../atoms/Heading";
import Date from "../atoms/Date";
import ButtonIcon from "../atoms/ButtonIcon";
import arrowIcon from "../../assets/icons/right-arrow-dark.svg";

const Post = ({ name, date, id }) => (
    <Wrapper>
        <Wrap>
            <Date>{date}</Date>
            <SHeading>{name}</SHeading>
        </Wrap>
        <StyledButtonIcon as={NavLink} to={routes.news + "/" + id} icon={arrowIcon}>
            czytaj więcej
        </StyledButtonIcon>
    </Wrapper>
);

Post.propTypes = {
    name: PropTypes.string,
    content: PropTypes.string,
    date: PropTypes.string,
    id: PropTypes.string,
};

Post.defaultProps = {
    name: "brak",
    date: "brak",
    id: "brak",
};

export default Post;

const Wrapper = styled.div`
    padding: 30px;
    background: white;
    border-radius: 14px;
    border: 1px solid black;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (${devices.md}) {
        margin-bottom: 0;
    }
`;

const Wrap = styled.div``;

const SHeading = styled(Heading)`
    font-size: 20px;
    margin-bottom: 30px;
`;

const StyledButtonIcon = styled(ButtonIcon)`
    background: none;
    font-weight: normal;
    padding: 10px 60px 10px 0;
    color: black;

    &:hover {
        background: none;
        text-decoration: underline;
    }
`;
