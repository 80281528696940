import React from "react";
import styled from "styled-components";
import { ProductsSettings } from "../../consts/ProductsSettings";
import PaginationItem from "../atoms/PaginationItem";
import uuid from "react-uuid";

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
`;
const Pagination = ({ totalProducts, paginate, activeProducts }) => {
    const pageNumber = [];
    const paginationCountElements = Math.ceil(totalProducts / ProductsSettings.NUMBER_OF_PRODUCTS_ON_PAGE);

    for (let i = 1; i <= paginationCountElements; i++) {
        pageNumber.push(i);
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Wrapper>
            {pageNumber.map((number) =>
                number === activeProducts ? (
                    <PaginationItem active onClick={() => paginate(number)} key={uuid()}>
                        {number}
                    </PaginationItem>
                ) : (
                    <PaginationItem
                        onClick={() => {
                            paginate(number);
                            scrollToTop();
                        }}
                        key={uuid()}
                    >
                        {number}
                    </PaginationItem>
                )
            )}
        </Wrapper>
    );
};
export default Pagination;
