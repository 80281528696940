import React, { memo } from "react";
import { RankingItem } from "../molecules/RankingItem";
import { ToggleWithContent } from "../molecules/ToggleWithContent";

export const RankingList = memo(({ value, name, index }) => {
    return (
        <ToggleWithContent heading={`Miesiąc: ${name}`} open={index === 0}>
            {value.map((rank, index) => (
                <RankingItem key={index} {...rank} />
            ))}
        </ToggleWithContent>
    );
});
