import styled, { css } from "styled-components";

export const Container = styled.div`
    margin: 0 auto;
    width: 100%;
    padding: 15px;
    max-width: ${({ theme }) => theme.containerWidth};

    ${({ fluid }) =>
        fluid &&
        css`
            width: ${({ theme }) => theme.fluidContainerWidth};
            max-width: 96%;
        `}
`;
