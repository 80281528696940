import React from "react";
import MainHeader from "../components/organisms/MainHeader";
import Banner from "../components/organisms/Banner";

const HomeTemplate = ({ children }) => (
    <>
        <MainHeader />
        {children}
        <Banner />
    </>
);

export default HomeTemplate;
