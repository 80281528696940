import React from "react";
import styled from "styled-components";
import { data } from "../../static/data";
import { devices } from "../../theme/devices";
import { Container } from "../atoms/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Heading from "../atoms/Heading";
import Paragraph from "../atoms/Paragraph";

const Steps = () => {
    const { stepsCards } = data;

    const step = ({ id, title, content }) => {
        return (
            <Step key={id}>
                <Icon icon={faCheck} />
                <StepNumber>Krok {id}</StepNumber>
                <SHeading as="h3">{title}</SHeading>
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </Step>
        );
    };

    return (
        <StepsContent>
            <Container>
                <Heading>Jak dokładnie wygląda współpraca?</Heading>
                <SParagraph>To proste! Zaczniesz zarabiać w kilka chwil. Poznaj 4 najważniejsze kroki:</SParagraph>
                <StepsWrapper>{stepsCards.map((card) => step({ ...card }))}</StepsWrapper>
            </Container>
        </StepsContent>
    );
};

export default Steps;

const StepsContent = styled("div")`
    background: ${({ theme }) => theme.backgroundColor};
    padding: 25px 0;

    @media (${devices.md}) {
        padding: 100px 0;
    }
`;

const SParagraph = styled(Paragraph)`
    margin-bottom: 100px;
`;

const StepNumber = styled("span")`
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0.5;
`;

const SHeading = styled(Heading)`
    font-size: 22px;
    max-width: 500px;
`;

const StepsWrapper = styled("div")``;

const Icon = styled(FontAwesomeIcon)`
    border-radius: 100vh;
    background-color: ${({ theme }) => theme.primaryColor};
    box-shadow: 0 0 0 2px rgb(243 241 255), 0 0 0 4px ${({ theme }) => theme.primaryColor}, 0 0 0 10px rgb(243 241 255);
    position: absolute;
    color: white;
    left: 9px;
    z-index: 100;
    padding: 10px;
`;

const Step = styled("div")`
    padding-left: 80px;
    position: relative;
    padding-bottom: 60px;
    max-width: 900px;

    @media (${devices.md}) {
        padding-left: 100px;
        padding-bottom: 100px;
    }

    &:after {
        content: "";
        position: absolute;
        width: 2px;
        height: 100%;
        background: ${({ theme }) => theme.primaryColor};
        left: 25px;
        top: 25px;
        z-index: 1;
    }

    &:last-of-type {
        padding-bottom: 0;
    }

    &:last-of-type:after {
        display: none;
    }
`;
