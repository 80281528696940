import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { ProductState } from "../../consts/ProductState";
import Heading from "../atoms/Heading";
import Paragraph from "../atoms/Paragraph";
import Strong from "../atoms/Strong";
import { routes } from "../../routes";
import availableIcon from "../../assets/icons/available.svg";
import lackIcon from "../../assets/icons/lack.svg";
import endingIcon from "../../assets/icons/ending.svg";
import { TabsWithText } from "../atoms/TabsWithText";

export const Product = (props) => {
    const { id, name, image, profit, price, productsKey, state } = props;

    const getStateIcon = (state) => {
        switch (state) {
            case ProductState.NOT_AVAILABLE:
                return <StateIcon activeColor={"secondaryAccentColor"} icon={lackIcon} />;
            case ProductState.LOW:
                return <StateIcon icon={endingIcon} />;
            case ProductState.NORMAL:
                return <StateIcon activeColor={"primaryColor"} icon={availableIcon} />;
        }
    };

    return (
        <Wrapper as={NavLink} to={routes.products + "/" + id}>
            <ImageWrapper>
                <Image src={image} />
            </ImageWrapper>
            <InnerWrapper>
                <Title>{name}</Title>
                <PriceWrapper>
                    <Price>{price} zł</Price>
                    <SParagraph>
                        Prowizja: <span>{profit} zł</span>
                    </SParagraph>
                </PriceWrapper>
                <Key secondary={state === ProductState.NOT_AVAILABLE} tertiary={state === ProductState.LOW}>
                    <Strong>{productsKey}</Strong>
                </Key>
            </InnerWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 14px;
    background: white;
    position: relative;
    box-shadow: ${({ theme }) => theme.shadow};
    width: 100%;
    text-decoration: none;
    transition-duration: 0.3s;
    overflow: hidden;

    &:hover {
        box-shadow: 0 2px 30px 0 RGBA(57, 97, 120, 0.22);
    }
`;

const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    height: 320px;
`;

const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 60%;
`;

const InnerWrapper = styled.div`
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Key = styled(TabsWithText)`
    position: absolute;
    top: 6px;
    right: 6px;
    font-size: 13px;
`;

const Title = styled(Heading)`
    font-size: 16px;
    height: 50px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.primaryTextColor};
`;

const PriceWrapper = styled("div")`
    display: flex;
    justify-content: space-between;
`;

const Price = styled(Paragraph)`
    color: ${({ theme }) => theme.primaryTextColor};
    font-weight: 700;
`;

const SParagraph = styled(Paragraph)`
    color: ${({ theme }) => theme.primaryTextColor};
    font-size: 14px;

    span {
        font-weight: bold;
        font-size: 16px;
        color: ${({ theme }) => theme.primaryAccentColor};
    }
`;

const StateIcon = styled.span`
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: ${({ activeColor, theme }) => (activeColor ? theme[activeColor] : theme.primaryTextColor)};
    background-image: url(${({ icon }) => icon});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: calc(100% - 2px);
    border-radius: 50px;
    top: 6px;
    right: 6px;
`;
