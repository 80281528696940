import React from "react";
import styled from "styled-components";

const Container = styled.div`
    background: ${({ theme }) => theme.primaryTextColor};
    z-index: -1;
`;
const Wrapper = styled.div`
    ${({ theme }) => theme.container};
    padding: 10px;
`;
const PageNotFoundTemplates = ({ children }) => (
    <Container>
        <Wrapper>{children}</Wrapper>
    </Container>
);

export default PageNotFoundTemplates;
