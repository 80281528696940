import { useEffect } from "react";

export const useDetectOutsideClick = (ref, setVisibility) => {
    useEffect(() => {
        document.addEventListener("mousedown", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
        };
    });

    const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
            return null;
        }

        setVisibility(false);
    };
};
