import React, { useContext } from "react";
import styled from "styled-components";
import Loading from "../components/molecules/Loading";
import PageTemplate from "../templates/PageTemplates";
import Heading from "../components/atoms/Heading";
import { PageContext } from "../context/PageContext";
import { Redirect } from "react-router-dom";

const ContentPage = styled.div`
    line-height: 26px;
    text-align: justify;
    color: ${({ theme }) => theme.primaryTextColor};
    & p {
        margin: 5px 0;
    }
    & h4 {
        font-size: 18px;
        margin: 10px 0;
    }
    & img {
        max-width: 100%;
        height: auto;
        border-radius: 10px;
    }
    @media (max-width: ${({ theme }) => theme.phone}) {
        font-size: 14px;
        & h4 {
            font-size: 15px;
        }
    }
`;

const Page = (props) => {
    const { pages, isLoadPages } = useContext(PageContext);

    let pageToRender;
    if (isLoadPages) {
        pageToRender = pages.filter((content) => content.friendlyUrl === props.match.params.id);
    }
    return isLoadPages ? (
        pageToRender.length ? (
            pageToRender.map((item) => (
                <PageTemplate title={item.title}>
                    <Heading>{item.title}</Heading>
                    <ContentPage dangerouslySetInnerHTML={{ __html: item.content }} />
                </PageTemplate>
            ))
        ) : (
            <Redirect to="/page-not-found" />
        )
    ) : (
        <PageTemplate>
            <Loading />
        </PageTemplate>
    );
};

export default Page;
