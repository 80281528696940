import React from "react";
import styled from "styled-components";
import Banner from "../components/organisms/Banner";
import SearchPanel from "../components/organisms/SearchPanel";
import Header from "../components/organisms/Header";
import { Container } from "../components/atoms/Container";

const ProductsTemplates = ({ children, content, searchredirect }) => (
    <main>
        <Header title="Produkty" image={false} content={content} />
        <SearchPanel searchredirect={searchredirect} />
        <Wrapper>{children}</Wrapper>
        <Banner />
    </main>
);

export default ProductsTemplates;

const Wrapper = styled(Container)`
    padding: 50px 0;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.tablet}) {
        padding: 25px 10px;
    }

    @media (max-width: ${({ theme }) => theme.phone}) {
    }
`;
