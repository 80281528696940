import Button from "../atoms/Button";
import styled from "styled-components";

const ButtonIcon = styled(Button)`
    position: relative;
    padding-right: 50px;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50px;
        width: 50px;
        height: 100%;
        background: url(${({ icon }) => icon});
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: auto 45%;
    }
`;

export default ButtonIcon;
