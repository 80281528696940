export const theme = {
    // Base colors
    primaryColor: "#1acc83",
    primaryAltColor: "#D0FBE1",
    secondaryColor: "#2F4858",
    secondaryAltColor: "#007C8C",
    tertiaryColor: "#08132b",

    // #1ACC83 - primary
    // #00B392
    // #009896
    // #007C8C - secondaryAlt
    // #146176
    // #2F4858 - secondary

    // Accent colors
    primaryAccentColor: "#14b8a3",
    primaryAccentAltColor: "#ccfbf1",
    tertiaryAccentColor: "#fac815",
    tertiaryAccentAltColor: "#faedcb",
    secondaryAccentColor: "#e1425e",
    secondaryAccentAltColor: "#ffe4e6",

    // Background colors
    backgroundColor: "#f7f6f6",

    // Border colors
    borderColor: "#e2e3e4",

    // Text colors
    primaryTextColor: "#2F4858",
    lightTextColor: "#FFFFFF",

    // Font family
    primaryFontFamily: "Inter, sans-serif",
    secondaryFontFamily: `"Inter", sans-serif`,

    danger_color: "#e43341",
    grey_color: "#dee3e9",

    shadow: "0px 2px 10px 0px RGBA(57,97,120,0.1)",

    container: {
        width: "100%",
        maxWidth: "1160px",
        margin: "0 auto",
    },

    tablet: "992px",
    phone: "768px",

    containerWidth: "1240px",
    fluidContainerWidth: "1640px",
};
