import React, { useContext } from "react";
import styled from "styled-components";
import Heading from "../components/atoms/Heading";
import { ProductsSettings } from "../consts/ProductsSettings";
import ProductsTemplates from "../templates/ProductsTemplates";
import { PageContext } from "../context/PageContext";
import { Product } from "../components/molecules/Product";
import Pagination from "../components/molecules/Pagination";
import { devices } from "../theme/devices";

export const ProductsPage = () => {
    const { isLoadProducts, currentPageProducts, setCurrentPageProducts, filterProducts } = useContext(PageContext);
    const indexOfLastPost = currentPageProducts * ProductsSettings.NUMBER_OF_PRODUCTS_ON_PAGE;
    const indexOfFirsPost = indexOfLastPost - ProductsSettings.NUMBER_OF_PRODUCTS_ON_PAGE;

    let currentPosts;

    if (filterProducts.length) {
        currentPosts = filterProducts.slice(indexOfFirsPost, indexOfLastPost);
    }

    const paginate = (pageNumber) => setCurrentPageProducts(pageNumber);

    return isLoadProducts ? (
        <ProductsTemplates>
            <Content>
                {currentPosts ? (
                    <>
                        {currentPosts.map(({ id, name, images, profit, priceGross, sku, state }) => (
                            <Product id={id} key={id} name={name} image={images[0]} profit={profit} price={priceGross} productsKey={sku} state={state} />
                        ))}
                    </>
                ) : (
                    <Heading small>Brak produktów</Heading>
                )}
            </Content>
            {filterProducts.length > ProductsSettings.NUMBER_OF_PRODUCTS_ON_PAGE && (
                <Pagination totalProducts={filterProducts.length} paginate={paginate} activeProducts={currentPageProducts} />
            )}
        </ProductsTemplates>
    ) : (
        <ProductsTemplates>
            <p>loading...</p>
        </ProductsTemplates>
    );
};

const Content = styled.div`
    @media (${devices.md}) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
    }

    @media (${devices.lg}) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 25px;
    }
`;
