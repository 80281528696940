import axios from "axios";
import React, { useContext, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { api } from "../../api";
import arrowIcon from "../../assets/icons/arrow-nav-black.svg";
import { PageContext } from "../../context/PageContext";
import { devices } from "../../theme/devices";
import Heading from "../atoms/Heading";

const Category = ({ isVisible, setVisibleBar, isMenuCategory, isVisibleMenu }) => {
    const { category, isLoadCategory, setCategory, setIsLoadCategory } = useContext(PageContext);
    const categoryListRef = useRef(null);

    useEffect(() => {
        if (!isLoadCategory) {
            axios
                .get(`${api.apiUrl}/category`, {
                    headers: { appToken: api.appToken },
                })
                .then(({ data }) => {
                    setCategory([...data.data]);
                    setIsLoadCategory(true);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    if (!category.length) return <></>;

    const getMainCategories = category?.filter((item) => item.idParent === 0);

    const getSubCategoriesById = (parentId) => category.filter((item) => item.idParent === parentId);

    return isLoadCategory ? (
        <CategoriesList
            categoryinmenu={isMenuCategory}
            ref={categoryListRef}
            style={{ maxHeight: isVisible ? categoryListRef?.current.scrollHeight + "px" : 0 }}
        >
            <SHeading>Wybierz kategorie:</SHeading>
            <Wrapper>
                {getMainCategories?.map(({ id, name }) => (
                    <div key={id}>
                        <CategoryItem
                            as={NavLink}
                            to={`/kategoria/${id}`}
                            onClick={() => {
                                if (isMenuCategory) {
                                    isVisibleMenu();
                                } else {
                                    setVisibleBar();
                                }
                            }}
                            categoryinmenu={isMenuCategory}
                        >
                            {name}
                        </CategoryItem>
                        {getSubCategoriesById(id).map((item) => (
                            <InnerCategoryItem
                                as={NavLink}
                                to={`/kategoria/${item.id}`}
                                key={item.id}
                                categoryinmenu={isMenuCategory}
                                onClick={() => {
                                    if (isMenuCategory) {
                                        isVisibleMenu();
                                    } else {
                                        setVisibleBar();
                                    }
                                }}
                            >
                                {item.name}
                            </InnerCategoryItem>
                        ))}
                    </div>
                ))}
            </Wrapper>
        </CategoriesList>
    ) : (
        <></>
    );
};
export default Category;

const CategoriesList = styled.ul`
    margin: 0;
    padding: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
`;

const SHeading = styled(Heading)`
    font-size: 20px;
    margin-bottom: 15px;
`;

const Wrapper = styled("div")`
    overflow: hidden;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (${devices.md}) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        height: 300px;
    }
`;

const CategoryItem = styled.li`
    list-style: none;
    display: block;
    padding: 5px 5px 5px 20px;
    position: relative;
    color: black;
    text-decoration: none;
    transition-duration: 0.3s;
    font-size: 14px;
    border-radius: 5px;

    &:before {
        content: "";
        width: 18px;
        height: 18px;
        background-image: url(${arrowIcon});
        background-repeat: no-repeat;
        background-size: 40%;
        background-position: 50%;
        position: absolute;
        left: 0;
        top: calc(50% - 9px);
    }

    &:hover {
        transform: translateX(10px);
    }

    ${({ categoryinmenu }) =>
        categoryinmenu &&
        css`
            font-weight: 700;
            width: 50%;
            font-size: 12px;
            padding: 2px 0 2px 20px;
        `};

    @media (max-width: ${({ theme }) => theme.phone}) {
        width: 100%;
    }
`;

const InnerCategoryItem = styled(CategoryItem)`
    padding-left: 45px;

    &:before {
        left: 20px;
    }
`;
