import React  from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ProductState } from "../../consts/ProductState";
import { devices } from "../../theme/devices";
import Paragraph from "../atoms/Paragraph";
import Date from "../atoms/Date";
import { NavLink } from "react-router-dom";
import { routes } from "../../routes";
import { TabsWithText } from "../atoms/TabsWithText";

export const StateItem = (props) => {
    const { productId, type, name, date } = props;

    const getStatusText = () => {
        switch (type) {
            case ProductState.NOT_AVAILABLE:
                return "niedostępne";
            case ProductState.DELIVERY:
                return "nowa dostawa";
            case ProductState.LOW_STATE:
                return "na wyczerpaniu";
            default:
                console.log("default", type);
        }
    };

    return (
        <Wrapper as={NavLink} to={routes.products + "/" + productId}>
            <Wrap>
                <Date>{date}</Date>
                <Name>{name}</Name>
            </Wrap>
            <STabsWithText secondary={type === ProductState.NOT_AVAILABLE} tertiary={type === ProductState.LOW_STATE}>
                {getStatusText()}
            </STabsWithText>
        </Wrapper>
    );
};

StateItem.propTypes = {
    productId: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
    id: PropTypes.number,
};

StateItem.defaultProps = {
    content: "brak",
    date: "brak",
};

const Wrapper = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.backgroundColor};
    border-radius: 12px;
    margin-bottom: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    border-bottom: 1px solid ${({ theme }) => theme.backgroundColor};
    transition: box-shadow 0.1s ease-out;

    &:hover {
        box-shadow: 1px 1px 6px 0 RGBA(57, 97, 120, 0.22);
    }
`;

const Name = styled(Paragraph)`
    font-weight: bold;
    margin-top: 20px;

    @media (${devices.md}) {
        margin-top: 0;
    }
`;

const Wrap = styled.div``;

const STabsWithText = styled(TabsWithText)`
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;

    @media (${devices.md}) {
        position: relative;
        right: 0;
        top: 0;
        font-size: 16px;
    }
`;
