import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *, *::after, *::before {
        box-sizing: border-box;
    }

   body {
	   font-family: 'Space Grotesk', sans-serif;
   }
   
   body, section, header {
       padding: 0;
       margin: 0;
   }
   
   p, h1, h2, h3, h4 {
       color: #2F4858
   }
`;

export default GlobalStyle;
