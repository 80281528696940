import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { ProductsSettings } from "../consts/ProductsSettings";
import { PageContext } from "../context/PageContext";
import ProductsTemplates from "../templates/ProductsTemplates";
import Loading from "../components/molecules/Loading";
import { Product } from "../components/molecules/Product";
import Pagination from "../components/molecules/Pagination";
import { devices } from "../theme/devices";

export const CategoryProductPage = (props) => {
    const { isLoadProducts, currentPageProducts, setCurrentPageProducts, filterProducts, setFilterPorducts, products, category, isLoadCategory } =
        useContext(PageContext);

    const [categoryName, setCategoryName] = useState("");
    const [isLoadCategoriesProducts, setIsLoadCategoriesProducts] = useState(false);
    const indexOfLastPost = currentPageProducts * ProductsSettings.NUMBER_OF_PRODUCTS_ON_PAGE;
    const indexOfFirsPost = indexOfLastPost - ProductsSettings.NUMBER_OF_PRODUCTS_ON_PAGE;

    let currentPosts;
    if (filterProducts.length) {
        currentPosts = filterProducts.slice(indexOfFirsPost, indexOfLastPost);
    }
    const paginate = (pageNumber) => setCurrentPageProducts(pageNumber);

    useEffect(() => {
        if (isLoadProducts === true && isLoadCategory === true) {
            setFilterList(props.match.params.id);
            setCategory(props.match.params.id);
        }
    }, [isLoadProducts, isLoadCategory, props]);

    const setFilterList = (id) => {
        setFilterPorducts([
            ...products.filter((item) => {
                let items;
                items = item.categories.join();
                return items.includes(id);
            }),
        ]);
        setCurrentPageProducts(1);
        setIsLoadCategoriesProducts(true);
    };

    const setCategory = (id) => {
        const activeCategory = category.filter((item) => item.id === id);
        if (activeCategory.length) {
            setCategoryName(activeCategory[0].name);
        }
    };

    return isLoadCategoriesProducts ? (
        <ProductsTemplates content={categoryName} searchredirect="true">
            <Content>
                {currentPosts ? (
                    <>
                        {currentPosts.map(({ id, name, images, profit, priceGross, sku, state }) => (
                            <Product id={id} key={id} name={name} image={images[0]} profit={profit} price={priceGross} productsKey={sku} state={state} />
                        ))}
                    </>
                ) : (
                    <p>Brak produktów</p>
                )}
            </Content>
            {filterProducts.length > ProductsSettings.NUMBER_OF_PRODUCTS_ON_PAGE && (
                <Pagination totalProducts={filterProducts.length} paginate={paginate} activeProducts={currentPageProducts} />
            )}
        </ProductsTemplates>
    ) : (
        <ProductsTemplates>
            <Loading />
        </ProductsTemplates>
    );
};

const Content = styled.div`
    @media (${devices.md}) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
    }

    @media (${devices.md}) {
        grid-template-columns: repeat(3, 1fr);
    }
`;
