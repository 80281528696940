import React from "react";
import AboutUs from "../components/molecules/AboutUs";
import Steps from "../components/molecules/Steps";
import BenefitsWrapper from "../components/organisms/BenefitsWrapper";
import RankingBar from "../components/organisms/RankingBar";
import HomeTemplate from "../templates/HomeTemplates";

const HomePage = () => (
    <HomeTemplate>
        <AboutUs />
        <Steps />
        <RankingBar />
        <BenefitsWrapper />
    </HomeTemplate>
);

export default HomePage;
