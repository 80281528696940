import React from "react";
import styled from "styled-components";
import { devices } from "../../theme/devices";
import { Container } from "../atoms/Container";
import Heading from "../atoms/Heading";
import Paragraph from "../atoms/Paragraph";

const Header = ({ title, content }) => {
    return (
        <Wrapper>
            <Description>
                <Heading white as={"h1"} size="30px">
                    {title}
                </Heading>
                {content && <Paragraph size={"18px"}>{content}</Paragraph>}
            </Description>
        </Wrapper>
    );
};

export default Header;

const Wrapper = styled.header`
    background: ${({ theme }) => theme.tertiaryColor};
    margin: 70px 0 0;

    @media (${devices.md}) {
        margin: 100px 0 0;
    }
`;

const Description = styled(Container)`
    padding: 20px 15px;
    display: flex;
    z-index: 1;
`;
