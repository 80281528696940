import React, { Component } from "react";
import MainNavigation from "../components/molecules/MainNavigation";
import { theme } from "../theme/mainTheme";
import GlobalStyle from "../theme/GlobalStyle";
import { ThemeProvider } from "styled-components";
import Footer from "../components/organisms/Footer";
import PageContextProvider from "../context/PageContext";
import Messenger from "../components/molecules/Messenger";

class MainTemplates extends Component {
    render() {
        const { children } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <PageContextProvider>
                    <MainNavigation />
                    {children}
                    <Footer />
                    <GlobalStyle />
                    <Messenger />
                </PageContextProvider>
            </ThemeProvider>
        );
    }
}

export default MainTemplates;
