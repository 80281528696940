import React, { useContext, useState, useEffect, useRef } from "react";
import logo from "../../assets/images/logo.png";
import styled, { css } from "styled-components";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import { routes } from "../../routes";
import { NavLink } from "react-router-dom";
import { devices } from "../../theme/devices";
import NavigationLink from "../atoms/NavigationLink";
import { InnerNavigation } from "./InnerNavigation";
import { PageContext } from "../../context/PageContext";
import Button from "../atoms/Button";

const MainNavigation = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);
    const { resetProductsFilters } = useContext(PageContext);
    const innerMenuRef = useRef(null);
    useDetectOutsideClick(innerMenuRef, setIsVisible);

    const setNavFixedFn = () => {
        setIsFixed(window.scrollY > 10);
    };

    useEffect(() => {
        document.addEventListener("scroll", setNavFixedFn);
        return () => document.removeEventListener("scroll", setNavFixedFn);
    });

    const isVisibleMenu = () => {
        setIsVisible(!isVisible);
    };
    const changeActiveMobileMenu = () => {
        setIsActiveMobileMenu(!isActiveMobileMenu);
    };

    return (
        <Wrapper isFixed={isFixed}>
            <a href={routes.home}>
                <MobileLogo src={logo} active={isFixed} />
            </a>
            <NavigationButton onClick={changeActiveMobileMenu} isActive={isActiveMobileMenu} />
            <Nav isActive={isActiveMobileMenu}>
                <ListWrapper>
                    <a href={routes.home}>
                        <Logo src={logo} active={isFixed} />
                    </a>
                    <ListItem>
                        <NavigationLink dropdown onClick={isVisibleMenu} activeItem={isVisible}>
                            Informacje
                        </NavigationLink>
                        <div ref={innerMenuRef}>
                            <InnerNavigation
                                isVisible={isVisible}
                                isVisibleMenu={isVisibleMenu}
                                closeMobileMenu={changeActiveMobileMenu}
                                isActiveMobileMenu={isActiveMobileMenu}
                            />
                        </div>
                    </ListItem>
                    <ListItem>
                        <NavigationLink
                            as={NavLink}
                            to={routes.news}
                            onClick={() => {
                                isActiveMobileMenu && changeActiveMobileMenu();
                            }}
                        >
                            Aktualności
                        </NavigationLink>
                    </ListItem>
                    <ListItem>
                        <NavigationLink
                            as={NavLink}
                            to={routes.ranking}
                            onClick={() => {
                                isActiveMobileMenu && changeActiveMobileMenu();
                            }}
                        >
                            Ranking
                        </NavigationLink>
                    </ListItem>
                    <ListItem>
                        <NavigationLink
                            as={NavLink}
                            to={routes.state}
                            onClick={() => {
                                isActiveMobileMenu && changeActiveMobileMenu();
                            }}
                        >
                            Zmiany stanów
                        </NavigationLink>
                    </ListItem>
                    <ListItem>
                        <NavigationLink
                            as={NavLink}
                            to={routes.products}
                            onClick={() => {
                                resetProductsFilters();
                                isActiveMobileMenu && changeActiveMobileMenu();
                            }}
                        >
                            Produkty
                        </NavigationLink>
                    </ListItem>
                </ListWrapper>

                <ListWrapper>
                    {/* <ListItem>
                        <PhoneNumber href="" small="true" target="_blank">
                            (+48) 533-898-788
                        </PhoneNumber>
                    </ListItem> */}
                    <ListItem>
                        <Button primary href={routes.salesPanel} small="true" target="_blank">
                            Panel sprzedawcy
                        </Button>
                    </ListItem>
                </ListWrapper>
            </Nav>
        </Wrapper>
    );
};

export default MainNavigation;

const Wrapper = styled.div`
    position: fixed;
    background: white;
    min-height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 500;

    ${({ isFixed }) => isFixed && css``}
`;

const Logo = styled.img`
    width: 180px;
    display: none;

    @media (${devices.md}) {
        display: block;
        margin-right: 30px;
    }
`;

const MobileLogo = styled.img`
    width: 130px;

    @media (${devices.md}) {
        display: none;
        margin-right: 30px;
    }
`;

const Nav = styled.div`
    background-color: white;
    width: 100%;
    height: 100vh;
    position: absolute;
    padding: 20px 0;
    z-index: 100;
    transform: translateX(100%);
    transition: transform 0.5s;

    ${({ isActive }) =>
        isActive &&
        css`
            transform: translateX(0);
        `}

    @media (${devices.md}) {
        display: flex;
        justify-content: space-between;
        transform: translateX(0);
        background: transparent;
        position: relative;
        height: auto;
        padding: 0 20px;
    }
`;

const ListWrapper = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;

    @media (${devices.md}) {
        align-items: center;
        flex-direction: row;
    }
`;

const ListItem = styled.li`
    text-decoration: none;
    padding: 20px;
    border: 1px solid ${({ theme }) => theme.backgroundColor};

    &:last-child {
        padding-right: 0;
    }

    @media (${devices.md}) {
        padding: 20px;
        border: none;
    }
`;

const NavigationButton = styled.div`
    position: absolute;
    z-index: 1000;
    width: 36px;
    height: 15px;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;

    &:before,
    &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: black;
        position: absolute;
        left: 0;
        transition: 0.2s;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }

    @media (${devices.md}) {
        display: none;
    }

    ${({ isActive }) =>
        isActive &&
        css`
            &:before {
                top: 50%;
                transform: rotate(45deg);
            }

            &:after {
                top: 50%;
                transform: rotate(-45deg);
            }
        `}
`;

const PhoneNumber = styled.a`
    color: ${({ theme }) => theme.primaryTextColor};
    font-weight: 700;
    text-decoration: none;

    @media (${devices.md}) {
        border-right: 1px solid ${({ theme }) => theme.borderColor};
        padding-right: 20px;
    }
`;
