import React, { useContext } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { PageContext } from "../../context/PageContext";
import { routes } from "../../routes";
import { devices } from "../../theme/devices";

export const InnerNavigation = ({ isVisible, isVisibleMenu, closeMobileMenu, isActiveMobileMenu }) => {
    const { pages, isLoadPages, resetProductsFilters } = useContext(PageContext);
    return isLoadPages ? (
        <Wrapper isVisible={isVisible}>
            {pages.map(({ title, friendlyUrl, position }) => (
                <NavigationItem key={position}>
                    {position === 1 ? (
                        <Link
                            as={NavLink}
                            to={routes.home}
                            onClick={() => {
                                isVisibleMenu();
                                if (isActiveMobileMenu) {
                                    closeMobileMenu();
                                }
                            }}
                        >
                            {title}
                        </Link>
                    ) : friendlyUrl === "produkty" ? (
                        <Link
                            as={NavLink}
                            to={routes.products}
                            onClick={() => {
                                resetProductsFilters();
                                isVisibleMenu();
                                if (isActiveMobileMenu) {
                                    closeMobileMenu();
                                }
                            }}
                        >
                            {title}
                        </Link>
                    ) : (
                        <Link
                            as={NavLink}
                            to={"/info/" + friendlyUrl}
                            onClick={() => {
                                isVisibleMenu();
                                if (isActiveMobileMenu) {
                                    closeMobileMenu();
                                }
                            }}
                        >
                            {title}
                        </Link>
                    )}
                </NavigationItem>
            ))}
        </Wrapper>
    ) : (
        <></>
    );
};

const Wrapper = styled.ul`
    padding: 10px 0;
    display: ${({ isVisible }) => (isVisible ? "block" : "none")};

    @media (${devices.md}) {
        text-align: center;
        top: 70px;
        transform: translateX(-25%);
        position: absolute;
        background-color: white;
        max-width: 300px;
        width: 100%;
        border-radius: 10px;
        z-index: 1000;
        box-shadow: ${({ theme }) => theme.shadow};
    }
`;

const NavigationItem = styled.li`
    list-style: none;
`;

const Link = styled.a`
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 4px;
    text-decoration: none;
    color: ${({ theme }) => theme.primaryTextColor};
    font-size: 14px;
    transition-duration: 0.3s;
    background-color: ${({ theme }) => theme.backgroundColor};
    border-radius: 8px;

    @media (${devices.md}) {
        font-weight: 700;
        background: transparent;
    }
`;
