import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { api } from "../api";
import Button from "../components/atoms/Button";
import { Container } from "../components/atoms/Container";
import Heading from "../components/atoms/Heading";
import Paragraph from "../components/atoms/Paragraph";
import Strong from "../components/atoms/Strong";
import { TabsWithText } from "../components/atoms/TabsWithText";
import ProductImages from "../components/molecules/ProductImages";
import { ToggleWithContent } from "../components/molecules/ToggleWithContent";
import Banner from "../components/organisms/Banner";
import Header from "../components/organisms/Header";
import { ProductState } from "../consts/ProductState";
import { devices } from "../theme/devices";

const DetailProductTemplates = ({ id, name, profit, description, images, price, sku, state }) => {
    // const [imagesList, setImagesList] = useState([]);
    // const [isLoadImagesList, setIsLoadImagesList] = useState(false);

    // useEffect(() => {
    //     axios
    //         .get(`${api.apiUrl}/product/${id}/images`, {
    //             headers: {
    //                 appToken: api.appToken,
    //             },
    //         })
    //         .then(({ data }) => {
    //             setIsLoadImagesList(true);
    //             setImagesList([...data.data]);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, [id]);

    const getProductState = () => {
        switch (state) {
            case ProductState.NOT_AVAILABLE:
                return "Niedostępny";
            case ProductState.LOW:
                return "Bliski wyczerpania";
            case ProductState.NORMAL:
                return "Dostępny";
            default:
                return "";
        }
    };

    // const getImagesDownloadLinks = () => {
    //     return (
    //         <ToggleWithContent heading="Zdjęcia do pobrania:">
    //             <Links>
    //                 {isLoadImagesList ? (
    //                     imagesList.map((image, index) => (
    //                         <DownloadButton key={index} href={"data:image/jpeg;base64," + image.base64} download={image.name}>
    //                             zdjęcie {index + 1}
    //                         </DownloadButton>
    //                     ))
    //                 ) : (
    //                     <Paragraph>Wczytywanie...</Paragraph>
    //                 )}
    //             </Links>
    //         </ToggleWithContent>
    //     );
    // };

    return (
        <>
            <Header title="Produkty" image={false} />
            <Container>
                <Wrapper>
                    <Sidebar>
                        <ProductImages images={images} />
                        <Key secondary>
                            Kod produktu: <Strong>{sku}</Strong>
                        </Key>
                    </Sidebar>
                    <Content>
                        <Title as="h1">{name}</Title>
                        <DetailsContent>
                            <Price>Cena: {price} zł</Price>
                            <Paragraph>
                                Twój zarobek <Strong>{profit}zł</Strong> (brutto)
                            </Paragraph>
                            <Paragraph margin={"10px 0"}>
                                Stan magazynowy:
                                <Status secondary={state === ProductState.NOT_AVAILABLE} tertiary={state === ProductState.LOW}>
                                    {" "}
                                    {getProductState()}
                                </Status>
                            </Paragraph>
                        </DetailsContent>
                    </Content>
                </Wrapper>
                <ToggleWithContent heading="Opis produktu:" alwaysOpen={true}>
                    <ProductContent dangerouslySetInnerHTML={{ __html: description }} />
                </ToggleWithContent>
                {/* {getImagesDownloadLinks()} */}
            </Container>
            <Banner />
        </>
    );
};

export default DetailProductTemplates;

const DownloadButton = styled(Button)`
    background-color: ${({ theme }) => theme.backgroundColor};
    color: black;
    font-size: 14px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    padding: 50px 0;
    gap: 50px;

    @media (${devices.md}) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const Title = styled(Heading)`
    font-size: 28px;
    margin-bottom: 50px;
`;

const Status = styled(TabsWithText)`
    background: none;
    font-weight: bold;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (${devices.md}) {
        width: 60%;
    }
`;

const Sidebar = styled.div`
    position: relative;

    @media (${devices.md}) {
        width: 40%;
    }
`;

const DetailsContent = styled("div")`
    border-radius: 14px;
`;

const Price = styled(TabsWithText)`
    display: inline-block;

    font-size: 36px;
    margin: 0 0 20px;
`;

const Key = styled(TabsWithText)`
    position: absolute;
    top: 10px;
    right: 10px;
`;
const Links = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
`;
const ProductContent = styled.div``;
