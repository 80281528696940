const size = {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
};

export const devices = {
    sm: `min-width: ${size.sm}`,
    md: `min-width: ${size.md}`,
    lg: `min-width: ${size.lg}`,
    xl: `min-width: ${size.xl}`,
    xxl: `min-width: ${size.xxl}`,
};
