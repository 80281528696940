import React from "react";
import styled from "styled-components";
import Button from "../atoms/Button";
import { Container } from "../atoms/Container";
import Heading from "../atoms/Heading";
import Paragraph from "../atoms/Paragraph";
import { routes } from "../../routes";
import bac from "../../assets/images/bac.svg";

const Banner = () => (
    <Wrapper>
        <Content>
            <SHeading white>Zainteresowany?</SHeading>
            <SParagraph white>
                Osoby współpracujące z nami zarabiają <span>nawet 8 000 zł</span> miesięcznie
            </SParagraph>
            <SParagraph white>
                Chcesz wiedzieć więcej i poznać szczegóły? Sprawdź zakładkę <span>JAK ZACZĄĆ?</span>
            </SParagraph>
            <SButton href={routes.salesPanel} target="_blank">
                Dołącz do nas
            </SButton>
        </Content>
    </Wrapper>
);

export default Banner;

const Wrapper = styled.section`
    overflow: hidden;
    background-color: ${({ theme }) => theme.tertiaryColor};
    background-image: url(${bac});
`;

const SHeading = styled(Heading)`
    margin-bottom: 30px;
`;

const SParagraph = styled(Paragraph)`
    opacity: 0.8;
    font-size: 18px;
    margin-bottom: 10px;

    span {
        font-weight: bold;
    }
`;

const Content = styled(Container)`
    padding: 150px 0;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.phone}) {
        padding: 100px 10px 150px;
    }
`;

const SButton = styled(Button)`
    margin-top: 50px;
    font-size: 22px;
`;
