import styled, { css } from "styled-components";

const Paragraph = styled.p`
    font-family: ${({ theme }) => theme.secondaryFontFamily};
    font-size: ${({ size }) => size || "1rem"};
    margin: ${({ margin }) => margin || "0"};
    font-weight: 300;
    line-height: 1.6rem;

    ${({ white }) =>
        white &&
        css`
            color: white;
        `};
`;

export default Paragraph;
