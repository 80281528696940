import React, { useContext } from "react";
import styled from "styled-components";
import NavigationLink from "../atoms/NavigationLink";
import { PageContext } from "../../context/PageContext";
import { NavLink } from "react-router-dom";
import { routes } from "../../routes";

export const FooterNavigation = () => {
    const { pages, isLoadPages } = useContext(PageContext);
    return isLoadPages ? (
        <Wrapper>
            {pages.map(({ title, friendlyUrl, position }) =>
                position === 1 ? (
                    <NavigationLink footer="true" as={NavLink} to={routes.home} key={position}>
                        {title}
                    </NavigationLink>
                ) : friendlyUrl === "produkty" ? (
                    <NavigationLink footer="true" as={NavLink} to={routes.products} key={position}>
                        {title}
                    </NavigationLink>
                ) : (
                    <NavigationLink footer="true" as={NavLink} to={"/info/" + friendlyUrl} key={position}>
                        {title}
                    </NavigationLink>
                )
            )}
        </Wrapper>
    ) : (
        <p>ala</p>
    );
};

const Wrapper = styled.nav`
    display: flex;
    flex-direction: column;
`;
