import React, { useContext } from "react";
import styled from "styled-components";
import { StateItem } from "../components/molecules/StateItem";
import PageTemplates from "../templates/PageTemplates";
import Loading from "../components/molecules/Loading";
import { PageContext } from "../context/PageContext";

export const StatusPage = () => {
    const { status, isLoadStatus } = useContext(PageContext);

    return (
        <PageTemplates title="Statusy">
            {isLoadStatus ? (
                <Wrapper>
                    {status.map(({ id, type, product, dateAdded }) => (
                        <StateItem key={id} productId={product.id} name={product.name} type={type} date={dateAdded} />
                    ))}
                </Wrapper>
            ) : (
                <Loading />
            )}
        </PageTemplates>
    );
};

const Wrapper = styled.div``;
