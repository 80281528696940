import React from "react";
import styled from "styled-components";
import { devices } from "../../theme/devices";
import { Container } from "../atoms/Container";
import Paragraph from "../atoms/Paragraph";
import Strong from "../atoms/Strong";
import { FooterNavigation } from "../molecules/FooterNavigation";
import logo from "../../assets/images/logo.png";
import { FooterTopBanner } from "../molecules/FooterTopBanner";
import { Copyright } from "../molecules/Copyright";
import { NavLink } from "react-router-dom";
import { routes } from "../../routes";
import NavigationLink from "../atoms/NavigationLink";

const Footer = () => (
    <section>
        <FooterTopBanner />
        <Wrapper>
            <div>
                <a href={routes.home}>
                    <Logo src={logo} />
                </a>
                <ShortDescription>
                    Dołączając do naszego zespołu otrzymujesz możliwość szybkiego zarobku jako pośrednik sprzedający nasze produkty.
                </ShortDescription>
                <SParagraph>
                    <Strong>KNM TEAM Spółka z ograniczoną odpowiedzialnością</Strong>
                </SParagraph>
                <SParagraph>
                    KRS: <Strong>0000999327</Strong>, NIP: <Strong>7372235890</Strong>
                </SParagraph>
                <SParagraph>ul. Bulwary 13,</SParagraph>
                <SParagraph>34-600 Limanowa</SParagraph>
            </div>
            <Navigation>
                <FooterHeading>Informacje</FooterHeading>
                <FooterNavigation />
            </Navigation>
            <Navigation>
                <FooterHeading>Pozostałe</FooterHeading>
                <NavigationLink footer="true" as={NavLink} to={routes.news}>
                    Aktualności
                </NavigationLink>
                <NavigationLink footer="true" as={NavLink} to={routes.ranking}>
                    Ranking
                </NavigationLink>
                <NavigationLink footer="true" as={NavLink} to={routes.products}>
                    Produkty
                </NavigationLink>
                <NavigationLink footer="true" as={NavLink} to={routes.state}>
                    Statusy
                </NavigationLink>
            </Navigation>
        </Wrapper>
        <Copyright />
    </section>
);

export default Footer;

const Wrapper = styled(Container)`
    padding-top: 50px;
    padding-bottom: 20px;
    justify-content: space-between;

    @media (${devices.sm}) {
        padding-top: 50px;
        padding-bottom: 50px;
        display: grid;
        gap: 50px;
        grid-template-columns: repeat(2, 1fr);
    }

    @media (${devices.md}) {
        grid-template-columns: 2fr 1fr 1fr;
    }
`;

const ShortDescription = styled(Paragraph)`
    margin-bottom: 20px;
`;

const SParagraph = styled(Paragraph)`
    font-size: 14px;
`;

const Logo = styled.img`
    width: 150px;
    margin-bottom: 15px;
`;

const Navigation = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @media (${devices.md}) {
        margin-top: 0;
    }
`;

const FooterHeading = styled("p")`
    font-family: ${({ theme }) => theme.secondaryFontFamily};
    font-weight: 700;
`;
