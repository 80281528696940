import productIcon from "../../assets/icons/ic_store.svg";
import rankIcon from "../../assets/icons/ic_rank.svg";
import panelIcon from "../../assets/icons/ic_panel.svg";

export const promoCards = [
    {
        id: "1",
        icon: productIcon,
        title: "Nasze produkty",
        content: "Setki produktów z różnych kategorii, które czekają na sprzedaż.",
        target: "https://teny.pl/produkty",
    },
    {
        id: "2",
        icon: rankIcon,
        title: "Ranking sprzedawców",
        content: "Sprzedawaj więcej i pobieraj premie. Sprawdź czy jesteś w TOP 8!",
        target: "https://teny.pl/ranking",
    },
    {
        id: "4",
        icon: panelIcon,
        title: "Panel sprzedawcy",
        content: "Twoje centrum dowodzenia i kontroli.",
        target: "https://m.teny.pl/",
    },
];
