import styled from "styled-components";

const Input = styled.input`
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    border-radius: 14px;
    font-size: ${({ size }) => size || "16px"};
    position: relative;
`;

export default Input;
