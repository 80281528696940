import React, { useState, useEffect } from "react";
import DetailProductTemplates from "../templates/DetailProductTemplates";
import Loading from "../components/molecules/Loading";
import PageTemplates from "../templates/PageTemplates";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { api } from "../api";

const DetailProductPage = (props) => {
    const [product, setProduct] = useState({});
    const [isLoad, setIsLoad] = useState(false);

    const redirectPageNotFound = () => {
        props.history.push("/page-not-found");
    };

    useEffect(() => {
        axios
            .get(`${api.apiUrl}/product/${props.match.params.id}`, {
                headers: {
                    appToken: api.appToken,
                },
                params: {
                    title: "",
                },
            })
            .then(({ data }) => {
                setProduct({ ...data.data });
                setIsLoad(true);
            })
            .catch((err) => {
                console.log(err);
                redirectPageNotFound();
            });
    }, []);

    return isLoad ? (
        <DetailProductTemplates
            id={product.id}
            name={product.name}
            profit={product.profit}
            description={product.description}
            images={product.images}
            categoryId={product.categoryId}
            price={product.priceGross}
            sku={product.sku}
            state={product.state}
        />
    ) : (
        <PageTemplates>
            <Loading />
        </PageTemplates>
    );
};
export default withRouter(DetailProductPage);
