import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { devices } from "../../theme/devices";
import Paragraph from "../atoms/Paragraph";
import Strong from "../atoms/Strong";
import { TabsWithText } from "../atoms/TabsWithText";

export const MainRankItem = memo((props) => {
    const { totalQuantity, user, position, reward, orderQuantity } = props;
    const { id } = user;

    return (
        <Wrapper>
            <Number>{position}</Number>
            <UserId>
                ID: <Strong>{id}</Strong>
            </UserId>
            <InfoWrapper>
                <Information>
                    <Paragraph size={"14px"}>
                        Zrealizowanych: <Strong>{orderQuantity}</Strong>
                    </Paragraph>
                    <Paragraph size={"14px"}>
                        Utworzonych: <Strong>{totalQuantity}</Strong>
                    </Paragraph>
                </Information>
                <TabsWithText>
                    Premia: <Strong>{reward} zł</Strong>
                </TabsWithText>
            </InfoWrapper>
        </Wrapper>
    );
});

MainRankItem.propTypes = {
    userID: PropTypes.number,
    crated: PropTypes.number,
    totalQuantity: PropTypes.number,
    orderQuantity: PropTypes.number,
    position: PropTypes.number,
    reward: PropTypes.number,
    main: PropTypes.bool,
};

MainRankItem.defaultProps = {
    userID: 0,
    crated: 0,
    totalQuantity: 0,
    orderQuantity: 0,
    position: 0,
    prize: 0,
};

const Wrapper = styled.div`
    background-color: white;
    margin-bottom: 10px;
    border-radius: 12px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const UserId = styled("span")`
    padding: 8px 14px;
    border-radius: 8px;
`;

const InfoWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 5px;
    flex: 1;

    @media (${devices.md}) {
        flex: 0.8;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: left;
    }
`;

const Information = styled.div``;

const Number = styled.span`
    width: 40px;
    aspect-ratio: 1;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.primaryTextColor};
    border: 2px solid ${({ theme }) => theme.primaryTextColor};
    border-radius: 100vmax;
`;
