import styled, { css } from "styled-components";

export const TabsWithText = styled("span")`
    border-radius: 8px;
    padding: 8px 14px;
    background-color: ${({ theme }) => theme.primaryAccentAltColor};
    color: ${({ theme }) => theme.primaryAccentColor};
    display: inline-block;

    ${({ secondary }) =>
        secondary &&
        css`
            background-color: ${({ theme }) => theme.secondaryAccentAltColor};
            color: ${({ theme }) => theme.secondaryAccentColor};
        `}

    ${({ secondary }) =>
        secondary &&
        css`
            background-color: ${({ theme }) => theme.secondaryAccentAltColor};
            color: ${({ theme }) => theme.secondaryAccentColor};
        `}

	${({ tertiary }) =>
        tertiary &&
        css`
            background-color: ${({ theme }) => theme.tertiaryAccentAltColor};
            color: ${({ theme }) => theme.tertiaryAccentColor};
        `}
`;
