import styled, { css } from "styled-components";
import { devices } from "../../theme/devices";

const Heading = styled.h2`
    font-size: 30px;
    margin: 0;
    padding: 0;
    width: 100%;
    font-weight: 700;

    @media (${devices.md}) {
        font-size: 40px;
    }

    @media (${devices.lg}) {
        font-size: 40px;
    }

    ${({ white }) =>
        white &&
        css`
            color: white;
        `};
`;

export default Heading;
