import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import arrowIcon from "../../assets/icons/arrow-nav.svg";
import Heading from "../atoms/Heading";

export const ToggleWithContent = (props) => {
    const { children, heading, open = false, alwaysOpen = false } = props;
    const contentRef = useRef(null);
    const [isOpen, setIsOpen] = useState(open);
    const styles = !alwaysOpen ? { maxHeight: isOpen ? contentRef?.current?.scrollHeight + "px" : 0 } : {};

    return (
        <Wrapper>
            <TopBar onClick={() => setIsOpen(!isOpen)} isActive={isOpen} alwaysOpen={alwaysOpen}>
                <SHeading>{heading}</SHeading>
            </TopBar>
            <Body ref={contentRef} style={styles} alwaysOpen={alwaysOpen}>
                <Content>{children}</Content>
            </Body>
        </Wrapper>
    );
};

ToggleWithContent.propTypes = {
    heading: PropTypes.string,
    open: PropTypes.bool,
    alwaysOpen: PropTypes.bool,
};

const Wrapper = styled("div")`
    margin-bottom: 10px;
`;

const TopBar = styled("div")`
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.4s;
    position: relative;
    background: ${({ theme }) => theme.tertiaryColor};
    border-radius: 12px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;

    &:before {
        content: ${({ alwaysOpen }) => (alwaysOpen ? null : "")};
        position: absolute;
        right: 10px;
        top: calc(50% - 13px);
        width: 26px;
        height: 26px;
        background: url(${arrowIcon});
        transition-duration: 0.4s;
        transform: ${({ isActive }) => (isActive ? "rotate(180deg)" : "rotate(0deg)")};
        border: 1px solid white;
        border-radius: 50%;
    }
`;

const SHeading = styled(Heading)`
    background: ${({ theme }) => theme.tertiaryColor};
    font-size: 18px;
    color: white;
`;

const Body = styled("div")`
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;

    ${({ alwaysOpen }) =>
        alwaysOpen &&
        css`
            max-height: 10000px;
        `}
`;

const Content = styled("div")`
    padding: 10px 0;
`;
