import React from "react";
import styled from "styled-components";
import Heading from "../atoms/Heading";
import { LinkWithArrow } from "../atoms/LinkWithArrow";
import Paragraph from "../atoms/Paragraph";

const PromoCard = ({ title, content, icon, target }) => {
    return (
        <Wrapper>
            <IconWrapper>
                <Icon src={icon} />
            </IconWrapper>
            <SHeading as={"h3"}>{title}</SHeading>
            <SParagraph>{content}</SParagraph>
            <LinkWithArrow text="zobacz więcej" primary={true} href={target} />
        </Wrapper>
    );
};

export default PromoCard;

const Wrapper = styled.div`
    border: 1px solid black;
    padding: 30px;
    margin-bottom: 20px;
    border-radius: 14px;

    &:nth-of-type(1) > span {
        background-color: ${({ theme }) => theme.tertiaryAccentAltColor};
    }

    &:nth-of-type(2) > span {
        background-color: ${({ theme }) => theme.primaryAccentAltColor};
    }

    &:nth-of-type(3) > span {
        background-color: ${({ theme }) => theme.secondaryAccentAltColor};
    }
`;

const SHeading = styled(Heading)`
    font-size: 18px;
    margin-bottom: 20px;
`;

const SParagraph = styled(Paragraph)`
    margin-bottom: 20px;
`;

const IconWrapper = styled("span")`
    margin-bottom: 30px;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
`;

const Icon = styled("img")``;
