import React from "react";
import styled from "styled-components";
import { data } from "../../static/data";
import { devices } from "../../theme/devices";
import { Container } from "../atoms/Container";
import Heading from "../atoms/Heading";
import Paragraph from "../atoms/Paragraph";
import BenefitCard from "../molecules/BenefitCard";

const BenefitsWrapper = () => {
    const { benefitCards } = data;

    return (
        <SContainer>
            <Heading>Dlaczego warto?</Heading>
            <Paragraph>8 najważniejszych korzyści</Paragraph>
            <BenefitCardsWrapper>
                {benefitCards.map((benefit) => (
                    <BenefitCard key={benefit.id} {...benefit} />
                ))}
            </BenefitCardsWrapper>
        </SContainer>
    );
};

export default BenefitsWrapper;

const SContainer = styled(Container)`
    padding: 100px 15px;
`;

const BenefitCardsWrapper = styled("div")`
    display: grid;
    margin: 80px 0 0;

    @media (${devices.md}) {
        grid-template-columns: repeat(2, 1fr);
        gap: 50px 100px;
    } ;
`;
