import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { devices } from "../../theme/devices";
import Input from "../atoms/Input";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Button from "../atoms/Button";
import { PageContext } from "../../context/PageContext";
import { NavLink } from "react-router-dom";
import { routes } from "../../routes";
import Paragraph from "../atoms/Paragraph";

export const SearchBar = () => {
    const { filterPorductsByName, setSearchValue } = useContext(PageContext);
    const [filterValue, setFilterValue] = useState();

    const filterProducts = () => {
        setSearchValue(filterValue);
        filterPorductsByName();
        setFilterValue("");
    };

    return (
        <Wrapper>
            <SParagraph>Szukaj produktów:</SParagraph>
            <InnerWrapper>
                <StyledInput
                    value={filterValue}
                    placeholder="Szukaj"
                    onChange={(e) => {
                        setFilterValue(e.target.value);
                    }}
                />
                <StyledButton secondary onClick={() => filterProducts()} as={NavLink} to={routes.products}>
                    <ButtonIcon icon={faMagnifyingGlass} />
                </StyledButton>
            </InnerWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    flex: 0.5;
`;

const SParagraph = styled(Paragraph)`
    font-weight: bold;
    margin-bottom: 1%;

    @media (${devices.md}) {
        margin-top: 0;
    }
`;

const InnerWrapper = styled.div`
    display: flex;
    position: relative;
    margin: 0;
    padding: 0;
`;

const StyledButton = styled(Button)`
    position: absolute;
    top: 1px;
    right: 1px;
    height: 48px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const ButtonIcon = styled(FontAwesomeIcon)``;

const StyledInput = styled(Input)`
    min-height: 50px;
`;
