import React from "react";
import styled from "styled-components";
import { devices } from "../../theme/devices";
import { Container } from "../atoms/Container";
import { LinkWithArrow } from "../atoms/LinkWithArrow";
import Paragraph from "../atoms/Paragraph";
import { SearchBar } from "../molecules/SearchBar";
import { routes } from "../../routes";

export const FooterTopBanner = () => (
    <Wrapper>
        <SContainer>
            <SearchBar />
            <InnerWrapper>
                <SParagraph>Jesteś sprzedawcą?</SParagraph>
                <LinkWithArrow text="Przejdź do panelu sprzedawcy" href={routes.salesPanel} blank={true} />
            </InnerWrapper>
        </SContainer>
    </Wrapper>
);

const Wrapper = styled.div`
    display: none;
    background: ${({ theme }) => theme.backgroundColor};

    @media (${devices.md}) {
        display: block;
    }
`;

const InnerWrapper = styled.div``;

const SParagraph = styled(Paragraph)`
    font-weight: bold;
    margin: 20px 0 10px;

    @media (${devices.md}) {
        margin-top: 0;
    }
`;

const SContainer = styled(Container)`
    padding: 25px 10px;

    @media (${devices.md}) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
