import { faRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

export const LinkWithArrow = (props) => {
    const { text, primary, href = "", blank } = props;

    return (
        <Link type={primary} href={href} target={blank && "_blank"}>
            <Text>{text}</Text>
            <ArrowIcon icon={faRightLong} type={primary} />
        </Link>
    );
};

LinkWithArrow.propTypes = {
    text: PropTypes.string,
    primary: PropTypes.bool,
    href: PropTypes.string,
    blank: PropTypes.bool,
};

const Link = styled("a")`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: ${({ type, theme }) => (type ? theme.primaryColor : theme.primaryTextColor)};
    text-decoration: none;
`;

const Text = styled("span")``;

const ArrowIcon = styled(FontAwesomeIcon)`
    color: ${({ type, theme }) => (type ? theme.primaryColor : theme.primaryTextColor)};
`;
