import { benefitCards } from "./parts/benefitCards";
import { mainHeader } from "./parts/mainHeader";
import { promoCards } from "./parts/promoCards";
import { stepsCards } from "./parts/stepsCards";

export const data = {
    mainHeader: mainHeader,
    promoCards: promoCards,
    benefitCards: benefitCards,
    stepsCards: stepsCards,
};
