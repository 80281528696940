export const benefitCards = [
    {
        id: "1",
        header: "Zarabiasz nawet kilka tysięcy zł miesięcznie.",
        description: "Dla jednych to jedyna wypłata, a dla innych sposób, by sobie dorobić. Rekordzista w miesiąc zarobił aż 15 000 zł na rękę!",
    },
    {
        id: "2",
        header: "Samodzielnie decydujesz o godzinach pracy.",
        description:
            "Pracujesz wtedy, kiedy Ci pasuje, np. tylko rano lub wieczorem. Nie ma sztywnych godzin, które musisz wyrobić. Jednak im szybciej działasz, tym więcej zarabiasz.",
    },
    {
        id: "3",
        header: "Pracujesz tyle, ile chcesz lub możesz.",
        description:
            "Masz małe dziecko, inną pracę lub właśnie wybierasz się na urlop? Nie ma sprawy – samodzielnie decydujesz o tym, kiedy zajmujesz się współpracą. Nie musisz nikogo prosić o zgodę na wolny dzień!",
    },
    {
        id: "4",
        header: "Unikasz inwestycji i wkładu własnego.",
        description:
            "Aby prowadzić sklep, zwykle trzeba zainwestować mnóstwo pieniędzy, m.in. w towar, stronę internetową czy wynajęcie lokalu. Z nami potrzebujesz tylko komputera lub telefonu oraz internetu. Sprzedajesz nasze przedmioty, więc inwestujesz tylko swój czas i zaangażowanie!",
    },
    {
        id: "5",
        header: "Nie zajmujesz się formalnościami, np. wysyłką.",
        description:
            "Twoim zadaniem jest jedynie wystawienie przedmiotu, a później obsługa zainteresowanych klientów np. na Messengerze. My zajmujemy się współpracą z firmą kurierską, zapakowaniem towaru, wystawieniem paragonu lub faktury oraz innymi formalnościami.",
    },
    {
        id: "6",
        header: "Oszczędzasz mnóstwo czasu na dojazdach do pracy.",
        description:
            "Pracujesz z domu lub innego dowolnego miejsca (np. na wakacjach), dlatego oszczędzasz nawet kilkanaście lub kilkadziesiąt godzin tygodniowo. Do tego dochodzi oszczędność np. na benzynie czy biletach.",
    },
    {
        id: "7",
        header: "Rozwijasz swoje umiejętności sprzedażowe.",
        description:
            "Otrzymasz od nas bezpłatne wskazówki, jak lepiej sprzedawać. Dzięki temu zaczniesz pracować jeszcze skuteczniej. Te umiejętności przydadzą Ci się nie tylko teraz, ale również w przyszłości.",
    },
    {
        id: "8",
        header: "Pracujesz w prosty sposób i doskonale sobie radzisz.",
        description:
            "To praca dla każdego, kto nie boi się zaangażowania. Nie ma znaczenia, ile masz lat (powyżej 16 r.ż.), jakie masz wykształcenie czy doświadczenie. Wszystko jest banalnie proste, nauczysz się naszego systemu raz-dwa.",
    },
];
